import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Pager from "../components/pager"
import pagedPageTitle from "../utils/paged-page-title"
import PageTitle from "../components/page-title"
import Teaser from "../components/tbpost/teaser"

const Template = ({ data }) => {
  const { post_area, posts } = data
  const { pageInfo: { currentPage } } = posts
  const title = pagedPageTitle(post_area.name ?? ``, currentPage)
  return (
    <Layout title={title} breadcrumbs={[{ label: title, href: null }]}>
      <PageTitle>{title}</PageTitle>
      <div>
        {
          (posts.nodes.length > 0) ?
            posts.nodes.map((post) => (
              <Teaser key={post.path} post={post} />
            )) : `記事が見つかりません。`
        }
      </div>
      <Pager pageInfo={posts.pageInfo} pathPrefix={post_area.path} />
    </Layout>
  )
}

export default Template

export const pageQuery = graphql`
  query($machine_name: String!, $skip: Int!, $limit: Int!) {
    post_area: tbPostArea(machine_name: { eq: $machine_name }) {
      name
      machine_name
      path
    }

    posts: allTbPost(
      filter: {post_areas: {elemMatch: {machine_name: {eq: $machine_name}}}},
      limit: $limit,
      skip: $skip,
      sort: {fields: [date, cms_id], order: [DESC, DESC]}
    ) {
      nodes {
        ...TbPostTeaser
      }
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        itemCount
        totalCount
        hasPreviousPage
      }
    }

  }
`
